import debounceRequestMixin from '@/services/debounceRequestMixin'
export default {
  mixins: [debounceRequestMixin],
  data() {
    return {
      thumbImage: {
        changed: false,
        loading: false,
        switch: false,
        search: '',
        category_id: undefined,
        main: null,
        page: 1,
        loaded: false,
        errors: [],
        selected: [],
        selectedOne: null,
      },
    }
  },
  computed: {
    thumbImages: function () {
      return this.$store.getters['image/searchedItems']
    },
    thumbImageNextCount: function () {
      const imageCount = this.thumbImages.count - this.thumbImages.data.length
      return imageCount > this.thumbImages.per_page ? this.thumbImages.per_page : imageCount
    },
  },
  methods: {
    isThumbImageSelected: function (isMultiple) {
      if (isMultiple) {
        return this.thumbImage.selected.length !== 0
      }
      return this.thumbImage.selectedOne !== undefined && this.thumbImage.selectedOne !== null
    },
    refreshThumbImage() {
      this.thumbImage = {
        changed: false,
        loading: false,
        switch: false,
        search: '',
        category_id: undefined,
        page: 1,
        loaded: false,
        errors: [],
        selected: [],
        selectedOne: null,
      }
    },
    onNextThumbImagesClick() {
      this.thumbImage.page += 1
      this.thumbImage.changed = false
      this.loadThumbSearchedImages(true)
    },
    onThumbImageSwitchChange(checked) {
      this.thumbImage.switch = checked
      if (checked && !this.thumbImage.loaded) {
        this.thumbImage.changed = true
        this.loadThumbSearchedImages()
      }
    },
    loadThumbSearchedImages(rollbackPage) {
      this.thumbImage.loaded = false
      this.thumbImage.loading = true
      this.loading = true
      if (this.thumbImage.changed) {
        this.thumbImage.page = 1
        this.thumbImage.errors = []
      }
      this.$store.dispatch('image/search', this.thumbImage)
        .catch(() => {
          if (rollbackPage) {
            this.thumbImage.page -= 1
          }
        })
        .finally(() => {
          this.loading = false
          this.thumbImage.loading = false
          this.thumbImage.loaded = true
        })
    },
  },
  mounted() {
    this.loadThumbSearchedImages = this.debounceRequest(this.loadThumbSearchedImages, 800)
  },
  watch: {
    'thumbImage.search'(newVal, oldVal) {
      if ((newVal.length === 0 || newVal.length > 2) && this.thumbImage.loading === false) {
        this.thumbImage.changed = true
        this.loadThumbSearchedImages()
      }
    },
    'thumbImage.category_id'(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.thumbImage.changed = true
        this.loadThumbSearchedImages()
      }
    },
  },
}
